import React from "react";
import ReactDOM from "react-dom/client";
import "./index.css";
import App from "./App";
import reportWebVitals from "./reportWebVitals";
import ScreenWidthProvider from "./modules/common/providers/ScreenWidthProvider";
import "react-toastify/dist/ReactToastify.min.css";
import { ToastContainer } from "react-toastify";
import { ApolloProvider } from "@apollo/client";
import ReactModal from "react-modal";
import client from "./lib/apollo";
import { HelmetProvider } from "react-helmet-async";

const root = ReactDOM.createRoot(
  document.getElementById("root") as HTMLElement
);
root.render(
  <React.StrictMode>
    <ScreenWidthProvider>
      <HelmetProvider>
        <ApolloProvider client={client}>
          <App />
          <ToastContainer
            position="top-right"
            autoClose={3000}
            hideProgressBar
            closeOnClick
            theme="dark"
          />
        </ApolloProvider>
      </HelmetProvider>
    </ScreenWidthProvider>
  </React.StrictMode>
);

ReactModal.setAppElement("#root");

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();

import { User as FirebaseUser, onAuthStateChanged } from "firebase/auth";
import React, {
  Suspense,
  createContext,
  useContext,
  useEffect,
  useState,
} from "react";
import {
  SnsType,
  User,
  useGetMyUserLazyQuery,
} from "../../../lib/apollo/graphql/generated";
import { firebaseAuth } from "../../../lib/firebase";
import { Outlet } from "react-router-dom";

interface AuthState {
  initializing: boolean;
  firebaseUser: FirebaseUser | null;
  profile?: {
    __typename?: "User";
    id: string;
    email: string;
    name?: string | null;
    username?: string | null;
    resignReason?: string | null;
    createdAt: any;
    updatedAt: any;
    deletedAt?: any | null;
    snsChannels: Array<{
      __typename?: "SNSChannel";
      id: string;
      type: SnsType;
    } | null>;
  } | null;
}

const defaultState: AuthState = {
  firebaseUser: null,
  initializing: true,
  profile: null,
};

const AuthContext = createContext<AuthState>(defaultState);

function AuthProvider() {
  const [state, setState] = useState<AuthState>({ ...defaultState });

  const [getMyUser] = useGetMyUserLazyQuery({
    fetchPolicy: "network-only",
  });

  useEffect(() => {
    onAuthStateChanged(firebaseAuth, async (firebaseUser) => {
      if (firebaseUser) {
        const { data } = await getMyUser();

        setState((prev) => ({
          ...prev,
          initializing: false,
          firebaseUser,
          profile: data?.getMyUser,
        }));
      } else {
        setState((prev) => ({
          ...defaultState,
          initializing: false,
        }));
      }
    });
  }, []);

  return (
    <AuthContext.Provider value={{ ...state }}>
      <Suspense fallback={<div />}>
        <Outlet />
      </Suspense>
    </AuthContext.Provider>
  );
}

export default AuthProvider;

export function useAuthContext() {
  return useContext(AuthContext);
}

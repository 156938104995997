import { ReactChildrenProp } from "global";
import React from "react";
import { useAuthContext } from "../../../modules/common/providers/AuthProvider";
import { Navigate, Outlet } from "react-router-dom";
import { SCREENS } from "../utils/router";

function PrivateRoute() {
  const { firebaseUser, initializing } = useAuthContext();

  if (initializing) {
    return <div />;
  }

  if (!firebaseUser) {
    return <Navigate to={SCREENS.SIGN_IN} />;
  }

  return <Outlet />;
}

export default PrivateRoute;

export const MIN_SCREEN_WIDTH: { [key: string]: number } = {
  DESKTOP: 900,
};

export const ALT_TEXT =
  "크레센더, SNS 컨텐츠 관리, 마케팅 컨텐츠 관리, 마케팅 자동화, 스몰 비즈니스, 자영업자, SNS 컨텐츠 분석, 일괄 업로드, SNS관리, 크레센도";

export const SNS_CHANNELS = {
  YOUTUBE: "YOUTUBE",
  INSTAGRAM: "INSTAGRAM",
  FACEBOOK: "FACEBOOK",
  TIKTOK: "TIKTOK",
};

import { Outlet, RouteObject, createBrowserRouter } from "react-router-dom";

import AuthProvider from "../../../modules/common/providers/AuthProvider";
import { lazy } from "react";
import PrivateRoute from "../components/PrivateRoute";

const LandingContainer = lazy(
  () => import("../../../modules/landing/container/LandingContainer")
);

const SignInContainer = lazy(
  () => import("../../../modules/auth/signIn/container/SignInContainer")
);

const SignUpIntroContainer = lazy(
  () =>
    import(
      "../../../modules/auth/signUp/signUpIntro/container/SignUpIntroContainer"
    )
);

const SignUpEmailContainer = lazy(
  () =>
    import(
      "../../../modules/auth/signUp/signUpEmail/container/SignUpEmailContainer"
    )
);

const ConnectContainer = lazy(
  () => import("../../../modules/connect/container/ConnectContainer")
);

const DashboardContainer = lazy(
  () => import("../../../modules/dashboard/container/DashboardContainer")
);

export const SCREENS = {
  LANDING: "/",

  SIGN_UP: "/sign_up",

  SIGN_UP_INTRO: "/start",

  SIGN_UP_EMAIL: "/email",

  SIGN_IN: "/sign_in",

  DASHBOARD: "/dashboard",

  FIRST_CONNECT: "/first_connect",

  CONNECT: "/connect",

  RESET_PASSWORD: "/reset_password",
};

const PRIVATE_CHILDREN: RouteObject[] = [
  {
    path: SCREENS.FIRST_CONNECT,
    element: <ConnectContainer />,
  },

  {
    path: SCREENS.CONNECT,
    element: <ConnectContainer />,
    children: [
      {
        path: SCREENS.CONNECT + "/:sns_type",
        element: <ConnectContainer />,
      },
    ],
  },

  {
    path: SCREENS.DASHBOARD,
    element: <DashboardContainer />,
  },
];

export const router = createBrowserRouter([
  {
    element: <AuthProvider />,
    children: [
      {
        path: SCREENS.LANDING,
        element: <LandingContainer />,
      },

      {
        path: SCREENS.SIGN_UP,
        element: <Outlet />,
        children: [
          {
            path: SCREENS.SIGN_UP + SCREENS.SIGN_UP_INTRO,
            element: <SignUpIntroContainer />,
          },

          {
            path: SCREENS.SIGN_UP + SCREENS.SIGN_UP_EMAIL,
            element: <SignUpEmailContainer />,
          },
        ],
      },

      {
        path: SCREENS.SIGN_IN,
        element: <SignInContainer />,
      },

      {
        path: SCREENS.RESET_PASSWORD,
        element: <div />,
      },

      {
        element: <PrivateRoute />,
        children: PRIVATE_CHILDREN,
      },
    ],
  },
]);

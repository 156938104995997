import { initializeApp } from "firebase/app";
import {
  addDoc,
  collection,
  doc,
  getDoc,
  getFirestore,
  setDoc,
} from "firebase/firestore";
import {
  EmailAuthProvider,
  createUserWithEmailAndPassword,
  getAuth,
  reauthenticateWithCredential,
  sendPasswordResetEmail,
  signInWithEmailAndPassword,
  signOut,
  updatePassword,
} from "firebase/auth";

const firebaseConfig = {
  apiKey: process.env.REACT_APP_FIREBASE_API_KEY,
  authDomain: process.env.REACT_APP_FIREBASE_AUTH_DOMAIN,
  projectId: process.env.REACT_APP_FIREBASE_PROJECT_ID,
  storageBucket: process.env.REACT_APP_FIREBASE_STORAGE_BUCKET,
  messagingSenderId: process.env.REACT_APP_FIREBASE_MESSAGING_SENDER_ID,
  appId: process.env.REACT_APP_FIREBASE_APP_ID,
  measurementId: process.env.REACT_APP_FIREBASE_MEASUREMENT_ID,
};

const app = initializeApp(firebaseConfig);
export const firebaseAuth = getAuth();

const firestore = getFirestore(app);

/////////////////////////////////////
// FIRESTORE
/////////////////////////////////////
export const FIRESTORE_RESULTS = {
  ALREADY_EXIST: "ALREADY_EXIST",
  SUCCESS: "SUCCESS",
  FAILED: "FAILED",
};

export async function addPreRegistration(contact: string) {
  try {
    const preRegistrationRef = collection(firestore, "pre_registration");
    const docRef = doc(firestore, "pre_registration", contact);
    const docSnap = await getDoc(docRef);

    if (docSnap.exists()) {
      return FIRESTORE_RESULTS.ALREADY_EXIST;
    } else {
      await setDoc(doc(preRegistrationRef, contact), {
        contact,
      });

      return FIRESTORE_RESULTS.SUCCESS;
    }
  } catch (err) {
    console.log(err);
    return FIRESTORE_RESULTS.FAILED;
  }
}

/////////////////////////////////////
// AUTH
/////////////////////////////////////

export async function firebaseSignUp(email: string, password: string) {
  return await createUserWithEmailAndPassword(firebaseAuth, email, password);
}

export async function firebaseSignIn(email: string, password: string) {
  await signInWithEmailAndPassword(firebaseAuth, email, password);
}

export async function firebaseSignOut() {
  await signOut(firebaseAuth);
}

export async function sendFirebasePasswordResetEmail(email: string) {
  await sendPasswordResetEmail(firebaseAuth, email);
}

export async function updateFirebasePassword(password: string) {
  if (firebaseAuth.currentUser) {
    await updatePassword(firebaseAuth.currentUser, password);
  }
}

export async function firebaseReAuthenticate(currentPassword: string) {
  const { currentUser } = firebaseAuth;
  if (currentUser) {
    const credential = EmailAuthProvider.credential(
      currentUser?.email!,
      currentPassword
    );
    await reauthenticateWithCredential(currentUser, credential);
  }
}

/**
 * It first checks if token is expired.
 * If expired, it return refreshed access token.
 * Otherwise, returns token from cache.
 */
export async function getFirebaseIdToken() {
  return await firebaseAuth.currentUser?.getIdToken();
}

export const FIREBASE_AUTH_ERROR_CODES = {
  "auth/user-not-found": "auth/user-not-found",
  "auth/invalid-password": "auth/invalid-password",
  "auth/email-already-exists": "auth/email-already-exists",
};

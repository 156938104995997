import { gql } from "@apollo/client";
import * as Apollo from "@apollo/client";
export type Maybe<T> = T | null;
export type InputMaybe<T> = Maybe<T>;
export type Exact<T extends { [key: string]: unknown }> = {
  [K in keyof T]: T[K];
};
export type MakeOptional<T, K extends keyof T> = Omit<T, K> & {
  [SubKey in K]?: Maybe<T[SubKey]>;
};
export type MakeMaybe<T, K extends keyof T> = Omit<T, K> & {
  [SubKey in K]: Maybe<T[SubKey]>;
};
export type MakeEmpty<
  T extends { [key: string]: unknown },
  K extends keyof T
> = { [_ in K]?: never };
export type Incremental<T> =
  | T
  | {
      [P in keyof T]?: P extends " $fragmentName" | "__typename" ? T[P] : never;
    };
const defaultOptions = {} as const;
/** All built-in and custom scalars, mapped to their actual values */
export type Scalars = {
  ID: { input: string; output: string };
  String: { input: string; output: string };
  Boolean: { input: boolean; output: boolean };
  Int: { input: number; output: number };
  Float: { input: number; output: number };
  Date: { input: any; output: any };
  DateTime: { input: any; output: any };
  PhoneNumber: { input: any; output: any };
  Upload: { input: any; output: any };
};

export type File = {
  __typename?: "File";
  encoding: Scalars["String"]["output"];
  filename: Scalars["String"]["output"];
  mimetype: Scalars["String"]["output"];
};

export enum Gender {
  Female = "FEMALE",
  Male = "MALE",
}

export type Media = {
  __typename?: "Media";
  createdAt: Scalars["DateTime"]["output"];
  id: Scalars["ID"]["output"];
  name: Scalars["String"]["output"];
  size: Scalars["Int"]["output"];
  /** This is provided only when thumbnailFile is uploaded */
  thumbnailUri?: Maybe<Scalars["String"]["output"]>;
  type: Scalars["String"]["output"];
  uri: Scalars["String"]["output"];
};

export type MediaInput = {
  file: Scalars["Upload"]["input"];
  thumbnailFile?: InputMaybe<Scalars["Upload"]["input"]>;
};

export type Mutation = {
  __typename?: "Mutation";
  _empty?: Maybe<Scalars["Boolean"]["output"]>;
  addSnsChannel: SnsChannel;
  createUser: User;
  removeSnsChannel: Scalars["Boolean"]["output"];
  resign: Scalars["Boolean"]["output"];
  updateMyUser: User;
};

export type MutationAddSnsChannelArgs = {
  refreshToken: Scalars["String"]["input"];
  snsType: SnsType;
};

export type MutationCreateUserArgs = {
  email: Scalars["String"]["input"];
  password: Scalars["String"]["input"];
};

export type MutationRemoveSnsChannelArgs = {
  snsChannelId: Scalars["ID"]["input"];
};

export type MutationResignArgs = {
  reason?: InputMaybe<Scalars["String"]["input"]>;
};

export type MutationUpdateMyUserArgs = {
  userInput: UserInput;
};

export type PageInfo = {
  __typename?: "PageInfo";
  endCursor?: Maybe<Scalars["String"]["output"]>;
  hasNextPage: Scalars["Boolean"]["output"];
};

export type PaginationInput = {
  after?: InputMaybe<Scalars["String"]["input"]>;
  first: Scalars["Int"]["input"];
};

export type Query = {
  __typename?: "Query";
  _empty?: Maybe<Scalars["Boolean"]["output"]>;
  getMySnsChannel: SnsChannel;
  getMySnsChannels: Array<Maybe<SnsChannel>>;
  getMyUser?: Maybe<User>;
  getUser: User;
  getUserSnsChannels: Array<Maybe<SnsChannel>>;
  getUsersAsAdmin: UserConnection;
};

export type QueryGetMySnsChannelArgs = {
  snsChannelId: Scalars["ID"]["input"];
};

export type QueryGetUserArgs = {
  userId: Scalars["ID"]["input"];
};

export type QueryGetUserSnsChannelsArgs = {
  userId: Scalars["ID"]["input"];
};

export type QueryGetUsersAsAdminArgs = {
  after?: InputMaybe<Scalars["String"]["input"]>;
  first?: InputMaybe<Scalars["Int"]["input"]>;
};

export type SnsChannel = {
  __typename?: "SNSChannel";
  accessToken?: Maybe<Scalars["String"]["output"]>;
  createdAt: Scalars["DateTime"]["output"];
  id: Scalars["ID"]["output"];
  name?: Maybe<Scalars["String"]["output"]>;
  refreshToken?: Maybe<Scalars["String"]["output"]>;
  thumbnailUri?: Maybe<Scalars["String"]["output"]>;
  type: SnsType;
  updatedAt: Scalars["DateTime"]["output"];
  user: User;
};

export enum SnsType {
  Facebook = "FACEBOOK",
  Instagram = "INSTAGRAM",
  Tiktok = "TIKTOK",
  Youtube = "YOUTUBE",
}

export type User = {
  __typename?: "User";
  createdAt: Scalars["DateTime"]["output"];
  deletedAt?: Maybe<Scalars["DateTime"]["output"]>;
  email: Scalars["String"]["output"];
  id: Scalars["ID"]["output"];
  name?: Maybe<Scalars["String"]["output"]>;
  resignReason?: Maybe<Scalars["String"]["output"]>;
  snsChannels: Array<Maybe<SnsChannel>>;
  updatedAt: Scalars["DateTime"]["output"];
  username?: Maybe<Scalars["String"]["output"]>;
};

export type UserConnection = {
  __typename?: "UserConnection";
  edges: Array<Maybe<User>>;
  pageInfo: PageInfo;
};

export type UserInput = {
  name?: InputMaybe<Scalars["String"]["input"]>;
  username?: InputMaybe<Scalars["String"]["input"]>;
};

export type GetMyUserQueryVariables = Exact<{ [key: string]: never }>;

export type GetMyUserQuery = {
  __typename?: "Query";
  getMyUser?: {
    __typename?: "User";
    id: string;
    email: string;
    name?: string | null;
    username?: string | null;
    resignReason?: string | null;
    createdAt: any;
    updatedAt: any;
    deletedAt?: any | null;
    snsChannels: Array<{
      __typename?: "SNSChannel";
      id: string;
      type: SnsType;
    } | null>;
  } | null;
};

export type CreateUserMutationVariables = Exact<{
  email: Scalars["String"]["input"];
  password: Scalars["String"]["input"];
}>;

export type CreateUserMutation = {
  __typename?: "Mutation";
  createUser: {
    __typename?: "User";
    id: string;
    email: string;
    name?: string | null;
    username?: string | null;
    resignReason?: string | null;
    createdAt: any;
    updatedAt: any;
    deletedAt?: any | null;
  };
};

export type UpdateMyUserMutationVariables = Exact<{
  userInput: UserInput;
}>;

export type UpdateMyUserMutation = {
  __typename?: "Mutation";
  updateMyUser: {
    __typename?: "User";
    id: string;
    email: string;
    name?: string | null;
    username?: string | null;
    resignReason?: string | null;
    createdAt: any;
    updatedAt: any;
    deletedAt?: any | null;
  };
};

export type ResignMutationVariables = Exact<{
  reason?: InputMaybe<Scalars["String"]["input"]>;
}>;

export type ResignMutation = { __typename?: "Mutation"; resign: boolean };

export type AddSnsChannelMutationVariables = Exact<{
  refreshToken: Scalars["String"]["input"];
  snsType: SnsType;
}>;

export type AddSnsChannelMutation = {
  __typename?: "Mutation";
  addSnsChannel: {
    __typename?: "SNSChannel";
    id: string;
    type: SnsType;
    name?: string | null;
    refreshToken?: string | null;
    accessToken?: string | null;
    thumbnailUri?: string | null;
    createdAt: any;
    updatedAt: any;
    user: { __typename?: "User"; id: string };
  };
};

export const GetMyUserDocument = gql`
  query GetMyUser {
    getMyUser {
      id
      email
      name
      username
      resignReason
      snsChannels {
        id
        type
      }
      createdAt
      updatedAt
      deletedAt
    }
  }
`;

/**
 * __useGetMyUserQuery__
 *
 * To run a query within a React component, call `useGetMyUserQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetMyUserQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetMyUserQuery({
 *   variables: {
 *   },
 * });
 */
export function useGetMyUserQuery(
  baseOptions?: Apollo.QueryHookOptions<GetMyUserQuery, GetMyUserQueryVariables>
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<GetMyUserQuery, GetMyUserQueryVariables>(
    GetMyUserDocument,
    options
  );
}
export function useGetMyUserLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    GetMyUserQuery,
    GetMyUserQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<GetMyUserQuery, GetMyUserQueryVariables>(
    GetMyUserDocument,
    options
  );
}
export function useGetMyUserSuspenseQuery(
  baseOptions?: Apollo.SuspenseQueryHookOptions<
    GetMyUserQuery,
    GetMyUserQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useSuspenseQuery<GetMyUserQuery, GetMyUserQueryVariables>(
    GetMyUserDocument,
    options
  );
}
export type GetMyUserQueryHookResult = ReturnType<typeof useGetMyUserQuery>;
export type GetMyUserLazyQueryHookResult = ReturnType<
  typeof useGetMyUserLazyQuery
>;
export type GetMyUserSuspenseQueryHookResult = ReturnType<
  typeof useGetMyUserSuspenseQuery
>;
export type GetMyUserQueryResult = Apollo.QueryResult<
  GetMyUserQuery,
  GetMyUserQueryVariables
>;
export const CreateUserDocument = gql`
  mutation CreateUser($email: String!, $password: String!) {
    createUser(email: $email, password: $password) {
      id
      email
      name
      username
      resignReason
      createdAt
      updatedAt
      deletedAt
    }
  }
`;
export type CreateUserMutationFn = Apollo.MutationFunction<
  CreateUserMutation,
  CreateUserMutationVariables
>;

/**
 * __useCreateUserMutation__
 *
 * To run a mutation, you first call `useCreateUserMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCreateUserMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [createUserMutation, { data, loading, error }] = useCreateUserMutation({
 *   variables: {
 *      email: // value for 'email'
 *      password: // value for 'password'
 *   },
 * });
 */
export function useCreateUserMutation(
  baseOptions?: Apollo.MutationHookOptions<
    CreateUserMutation,
    CreateUserMutationVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<CreateUserMutation, CreateUserMutationVariables>(
    CreateUserDocument,
    options
  );
}
export type CreateUserMutationHookResult = ReturnType<
  typeof useCreateUserMutation
>;
export type CreateUserMutationResult =
  Apollo.MutationResult<CreateUserMutation>;
export type CreateUserMutationOptions = Apollo.BaseMutationOptions<
  CreateUserMutation,
  CreateUserMutationVariables
>;
export const UpdateMyUserDocument = gql`
  mutation UpdateMyUser($userInput: UserInput!) {
    updateMyUser(userInput: $userInput) {
      id
      email
      name
      username
      resignReason
      createdAt
      updatedAt
      deletedAt
    }
  }
`;
export type UpdateMyUserMutationFn = Apollo.MutationFunction<
  UpdateMyUserMutation,
  UpdateMyUserMutationVariables
>;

/**
 * __useUpdateMyUserMutation__
 *
 * To run a mutation, you first call `useUpdateMyUserMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateMyUserMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateMyUserMutation, { data, loading, error }] = useUpdateMyUserMutation({
 *   variables: {
 *      userInput: // value for 'userInput'
 *   },
 * });
 */
export function useUpdateMyUserMutation(
  baseOptions?: Apollo.MutationHookOptions<
    UpdateMyUserMutation,
    UpdateMyUserMutationVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<
    UpdateMyUserMutation,
    UpdateMyUserMutationVariables
  >(UpdateMyUserDocument, options);
}
export type UpdateMyUserMutationHookResult = ReturnType<
  typeof useUpdateMyUserMutation
>;
export type UpdateMyUserMutationResult =
  Apollo.MutationResult<UpdateMyUserMutation>;
export type UpdateMyUserMutationOptions = Apollo.BaseMutationOptions<
  UpdateMyUserMutation,
  UpdateMyUserMutationVariables
>;
export const ResignDocument = gql`
  mutation Resign($reason: String) {
    resign(reason: $reason)
  }
`;
export type ResignMutationFn = Apollo.MutationFunction<
  ResignMutation,
  ResignMutationVariables
>;

/**
 * __useResignMutation__
 *
 * To run a mutation, you first call `useResignMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useResignMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [resignMutation, { data, loading, error }] = useResignMutation({
 *   variables: {
 *      reason: // value for 'reason'
 *   },
 * });
 */
export function useResignMutation(
  baseOptions?: Apollo.MutationHookOptions<
    ResignMutation,
    ResignMutationVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<ResignMutation, ResignMutationVariables>(
    ResignDocument,
    options
  );
}
export type ResignMutationHookResult = ReturnType<typeof useResignMutation>;
export type ResignMutationResult = Apollo.MutationResult<ResignMutation>;
export type ResignMutationOptions = Apollo.BaseMutationOptions<
  ResignMutation,
  ResignMutationVariables
>;
export const AddSnsChannelDocument = gql`
  mutation AddSnsChannel($refreshToken: String!, $snsType: SNSType!) {
    addSnsChannel(refreshToken: $refreshToken, snsType: $snsType) {
      id
      user {
        id
      }
      type
      name
      refreshToken
      accessToken
      thumbnailUri
      createdAt
      updatedAt
    }
  }
`;
export type AddSnsChannelMutationFn = Apollo.MutationFunction<
  AddSnsChannelMutation,
  AddSnsChannelMutationVariables
>;

/**
 * __useAddSnsChannelMutation__
 *
 * To run a mutation, you first call `useAddSnsChannelMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useAddSnsChannelMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [addSnsChannelMutation, { data, loading, error }] = useAddSnsChannelMutation({
 *   variables: {
 *      refreshToken: // value for 'refreshToken'
 *      snsType: // value for 'snsType'
 *   },
 * });
 */
export function useAddSnsChannelMutation(
  baseOptions?: Apollo.MutationHookOptions<
    AddSnsChannelMutation,
    AddSnsChannelMutationVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<
    AddSnsChannelMutation,
    AddSnsChannelMutationVariables
  >(AddSnsChannelDocument, options);
}
export type AddSnsChannelMutationHookResult = ReturnType<
  typeof useAddSnsChannelMutation
>;
export type AddSnsChannelMutationResult =
  Apollo.MutationResult<AddSnsChannelMutation>;
export type AddSnsChannelMutationOptions = Apollo.BaseMutationOptions<
  AddSnsChannelMutation,
  AddSnsChannelMutationVariables
>;

import React from "react";
import RouteContainer from "./lib/route/container/RouterContainer";
import { Helmet } from "react-helmet-async";

function App() {
  return (
    <>
      <RouteContainer />
      <Helmet>
        <script>
          {`window.fbAsyncInit = function() {
                <!-- Initialize the SDK with your app and the Graph API version for your app -->
                FB.init({
                          appId : '${process.env.REACT_APP_FACEBOOK_APP_ID}',
                          xfbml : true,
                          version : 'v19.0'
                        });
            };`}
        </script>
        <script
          async
          crossOrigin="anonymous"
          src="https://connect.facebook.net/en_US/sdk.js"
        ></script>
      </Helmet>
    </>
  );
}

export default App;
